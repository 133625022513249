import React, { useEffect, useState } from 'react';
import { HeaderScope, FabricHeaderOptions } from '../types';
import { css, cx } from 'emotion';
import { PanelData } from '@grafana/data';

import { dotStateForNumber, getNumberFromQuery, DotState } from 'uef-common';

import { StatusDot } from './StatusDot';

const schmalzCobot = require('../img/schmalz_cobot.png');
const arburgAllrounder = require('../img/arburg_allrounder.png');
const kochBlister = require('../img/koch_blister.png');
const buerklePresse = require('../img/buerkle_presse.png');
const homagCnc = require('../img/homag_cnc.png');
const useCaseUltraEfficientPlantSimulation = require('../img/usecase_ultraefficient_plant_simulation.png');

interface ImageWithDotsOptions {
  options: FabricHeaderOptions;
  data: PanelData;
  height: number;
}

interface StatusDotConfig {
  x: number;
  y: number;
  title: string;
  description: string;
  dotState: DotState;
}

export const ImageWithDots: React.FC<ImageWithDotsOptions> = ({ data, options, height }) => {
  //   const styles = getStyles();
  const [image, setImage] = useState<any>();

  const [statusDots, setStatusDots] = useState<StatusDotConfig[]>([]);

  useEffect(() => {
    const newDots: StatusDotConfig[] = [];

    if (options.dot1StatusQueryName && options.statusDotCount > 0) {
      const dot1Value = getNumberFromQuery(data, options.dot1StatusQueryName);
      newDots.push({
        x: options.dot1XPosition,
        y: options.dot1YPosition,
        title: options.dot1Title,
        description: options.dot1Description,
        dotState: dotStateForNumber(dot1Value),
      });
    }

    if (options.dot2StatusQueryName && options.statusDotCount > 1) {
      const dot2Value = getNumberFromQuery(data, options.dot2StatusQueryName);
      newDots.push({
        x: options.dot2XPosition,
        y: options.dot2YPosition,
        title: options.dot2Title,
        description: options.dot2Description,
        dotState: dotStateForNumber(dot2Value),
      });
    }

    if (options.dot3StatusQueryName && options.statusDotCount > 2) {
      const dot3Value = getNumberFromQuery(data, options.dot3StatusQueryName);
      newDots.push({
        x: options.dot3XPosition,
        y: options.dot3YPosition,
        title: options.dot3Title,
        description: options.dot3Description,
        dotState: dotStateForNumber(dot3Value),
      });
    }

    if (options.dot4StatusQueryName && options.statusDotCount > 3) {
      const dot4Value = getNumberFromQuery(data, options.dot4StatusQueryName);
      newDots.push({
        x: options.dot4XPosition,
        y: options.dot4YPosition,
        title: options.dot4Title,
        description: options.dot4Description,
        dotState: dotStateForNumber(dot4Value),
      });
    }

    if (options.dot5StatusQueryName && options.statusDotCount > 4) {
      const dot5Value = getNumberFromQuery(data, options.dot5StatusQueryName);
      newDots.push({
        x: options.dot5XPosition,
        y: options.dot5YPosition,
        title: options.dot5Title,
        description: options.dot5Description,
        dotState: dotStateForNumber(dot5Value),
      });
    }

    setStatusDots(newDots);
  }, [
    options.statusDotCount,
    options.dot1StatusQueryName,
    options.dot1XPosition,
    options.dot1YPosition,
    options.dot2StatusQueryName,
    options.dot2XPosition,
    options.dot2YPosition,
    options.dot3StatusQueryName,
    options.dot3XPosition,
    options.dot3YPosition,
    options.dot4StatusQueryName,
    options.dot4XPosition,
    options.dot4YPosition,
    options.dot5StatusQueryName,
    options.dot5XPosition,
    options.dot5YPosition,
  ]);

  useEffect(() => {
    switch (options.headerScope) {
      case HeaderScope.Arburg:
        setImage(arburgAllrounder);
        break;
      case HeaderScope.Homag:
        setImage(homagCnc);
        break;
      case HeaderScope.KochPac:
        setImage(kochBlister);
        break;
      case HeaderScope.Buerkle:
        setImage(buerklePresse);
        break;
      case HeaderScope.Schmalz:
        setImage(schmalzCobot);
        break;
      case HeaderScope.UCSmartMaintenance:
        setImage(arburgAllrounder);
        break;
      case HeaderScope.UCPlantSimulation:
        setImage(useCaseUltraEfficientPlantSimulation);
        break;
    }
  }, [options]);

  return (
    <>
      {options.showThreeDTour && (
        <iframe
          className={cx(
            css`
              width: 100%;
              height: 100%;
              object-fit: contain;
            `
          )}
          src={options.threeDTourUrl}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      )}

      <div
        className={cx(
          css`
            position: relative;
            display: flex;
            max-height: ${height * 0.95 > 500 ? 500 : height * 0.95}px;
          `
        )}
      >
        {!options.showThreeDTour && (
          <img
            src={image}
            className={cx(
              css`
                width: 100%;
                object-fit: contain;
              `
            )}
          ></img>
        )}

        {statusDots.map((dot, index) => (
          <div
            key={`dot_${index}`}
            className={cx(
              css`
                top: ${dot.y}%;
                left: ${dot.x}%;
                position: absolute;
              `
            )}
          >
            <StatusDot dotState={dot.dotState} infoMessage={dot.description} infoTitle={dot.title} />
          </div>
        ))}
      </div>
    </>
  );
};
