import React from 'react';
import { css, cx } from 'emotion';
import { Modal } from '@grafana/ui';

export enum DotState {
  green,
  red,
  white,
}

interface Props {
  dotState: DotState;
  infoTitle: string | undefined;
  infoMessage: string | undefined;
  useModal?: boolean;
}

export const StatusDot = ({ dotState, infoTitle, infoMessage, useModal }: Props) => {
  const [hovered, setHovered] = React.useState(false);
  const [showPopover, setShowPopover] = React.useState(false);
  const color = dotState === DotState.red ? '#E7302A' : dotState === DotState.green ? '#69B01B' : 'white';

  const InfoBox = () => (
    <div>
      <p
        className={css`
          font-size: 1.3rem;
          font-weight: 600;
          text-color: #e7e7e7;
        `}
      >
        {infoTitle ?? 'Bitte Titel eintragen'}
      </p>
      <p
        className={css`
          text-color: #e7e7e7;
        `}
      >
        {infoMessage ?? 'Bitte Beschreibung eintragen'}
      </p>
    </div>
  );

  return (
    <div>
      <div
        onClick={
          useModal
            ? () => {
                setShowPopover(true);
              }
            : undefined
        }
        onAbort={
          useModal
            ? () => {
                setShowPopover(false);
              }
            : undefined
        }
        onMouseEnter={useModal ? undefined : () => setHovered(true)}
        onMouseLeave={useModal ? undefined : () => setHovered(false)}
        className={cx(
          css`
            transform: scale(${hovered ? 1.2 : 1.0});
          `
        )}
      >
        <svg viewBox="0 0 40 40" width={20} height={20}>
          <circle cx="20" cy="20" r="18" stroke={color} fill="none" strokeWidth={3} />
          <circle cx="20" cy="20" r="8" fill={color} />
        </svg>
      </div>
      {hovered && (
        <div
          className={css`
            border-radius: 20px;
            background-color: #313131;
            box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.1);
            top: 15px;
            left: 15px;
            width: 300px;
            position: absolute;
            z-index: 1000;
            padding: 2em;
          `}
        >
          <InfoBox />
        </div>
      )}
      <Modal isOpen={showPopover} onDismiss={() => setShowPopover(false)} title={''}>
        <InfoBox />
      </Modal>
    </div>
  );
};
