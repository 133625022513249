import { PanelPlugin } from '@grafana/data';
import { FabricHeaderOptions, HeaderScope } from './types';
import { HeaderComponent } from './components/HeaderComponent';

type HeaderScopeOption = {
  value: HeaderScope;
  label: string;
};

const headerScopeOptions: HeaderScopeOption[] = [
  {
    value: HeaderScope.Arburg,
    label: 'ARBURG',
  },
  {
    value: HeaderScope.Homag,
    label: 'HOMAG Group',
  },
  {
    value: HeaderScope.KochPac,
    label: 'KOCH Pac-Systeme',
  },
  {
    value: HeaderScope.Buerkle,
    label: 'Robert Bürkle',
  },
  {
    value: HeaderScope.Schmalz,
    label: 'J.Schmalz',
  },
  {
    value: HeaderScope.UCSmartMaintenance,
    label: 'Smart Maintenance (UseCase)',
  },
  {
    value: HeaderScope.UCPlantSimulation,
    label: 'Ultraefficient Plant Simulation (UseCase)',
  },
];

const statusDotSettings = {
  defaultValue: 0,
  category: ['Punkte'],
  settings: {
    max: 100,
    min: 0,
    step: 1,
  },
};

const machineShortDescriptionLeftVisible = (options: FabricHeaderOptions): boolean => {
  return options.headerScope !== 'Smart Maintenance';
};

const machineShortDescriptionRightVisible = (options: FabricHeaderOptions): boolean => {
  return options.headerScope !== 'Smart Maintenance' && options.headerScope !== 'Ultraefficient Plant Simulation';
};

export const plugin = new PanelPlugin<FabricHeaderOptions>(HeaderComponent).setPanelOptions((builder) => {
  return builder
    .addSelect({
      path: 'headerScope',
      defaultValue: 'ARBURG',
      name: 'Anwendungsbereich',
      settings: {
        options: headerScopeOptions,
      },
    })
    .addBooleanSwitch({
      path: 'showThreeDTour',
      name: '3D Rundgang',
      description: '3D Rundgang anstelle des Standardbilds anzeigen.',
      defaultValue: false,
    })
    .addTextInput({
      path: 'threeDTourUrl',
      name: 'Url zum Rundgang',
      description: 'Url zum Rundgang, der in einem iframe dargstellt wird',
      showIf: (currentOptions, data) => currentOptions.showThreeDTour,
    })
    .addTextInput({
      path: 'machineShortDescriptionLeft',
      name: 'Kurzinfo (Links)',
      description: 'Beschreibung was das Asset kann etc. Nicht mehr als 20 Wörter.',
      defaultValue: '',
      showIf: (currentOptions, data) => machineShortDescriptionLeftVisible(currentOptions),
    })
    .addTextInput({
      path: 'machineShortDescriptionRight',
      name: 'Kurzinfo (Rechts)',
      description: 'Beschreibung was das Asset kann etc. Nicht mehr als 20 Wörter.',
      defaultValue: '',
      showIf: (currentOptions, data) => machineShortDescriptionRightVisible(currentOptions),
    })
    .addNumberInput({
      path: 'cardCount',
      name: 'Anzahl Kacheln',
      defaultValue: 3,
      category: ['Kacheln'],
      settings: {
        integer: true,
        min: 0,
        max: 5,
        step: 1,
      },
    })
    .addTextInput({
      path: 'column1ValueQueryName',
      name: 'Wert Spalte 1 (QueryName)',
      description: 'Name der Query',
      defaultValue: '',
      category: ['Kacheln'],
      showIf: (currentOptions, data) => currentOptions.cardCount > 0,
    })
    .addTextInput({
      path: 'column1ValueUnit',
      name: 'Wert Spalte 1 (Einheit)',
      description: 'Maßeinheit',
      defaultValue: '',
      category: ['Kacheln'],
      showIf: (currentOptions, data) => currentOptions.cardCount > 0,
    })
    .addTextInput({
      path: 'column2ValueQueryName',
      name: 'Wert Spalte 2 (QueryName)',
      description: 'Name der Query',
      defaultValue: '',
      category: ['Kacheln'],
      showIf: (currentOptions, data) => currentOptions.cardCount > 1,
    })
    .addTextInput({
      path: 'column2ValueUnit',
      name: 'Wert Spalte 2 (Einheit)',
      description: 'Maßeinheit',
      defaultValue: '',
      category: ['Kacheln'],
      showIf: (currentOptions, data) => currentOptions.cardCount > 1,
    })
    .addTextInput({
      path: 'column3ValueQueryName',
      name: 'Wert Spalte 3 (QueryName)',
      description: 'Name der Query',
      defaultValue: '',
      category: ['Kacheln'],
      showIf: (currentOptions, data) => currentOptions.cardCount > 2,
    })
    .addTextInput({
      path: 'column3ValueUnit',
      name: 'Wert Spalte 3 (Einheit)',
      description: 'Maßeinheit',
      defaultValue: '',
      category: ['Kacheln'],
      showIf: (currentOptions, data) => currentOptions.cardCount > 2,
    })
    .addTextInput({
      path: 'column4ValueQueryName',
      name: 'Wert Spalte 4 (QueryName)',
      description: 'Name der Query',
      defaultValue: '',
      category: ['Kacheln'],
      showIf: (currentOptions, data) => currentOptions.cardCount > 3,
    })
    .addTextInput({
      path: 'column4ValueUnit',
      name: 'Wert Spalte 4 (Einheit)',
      description: 'Maßeinheit',
      defaultValue: '',
      category: ['Kacheln'],
      showIf: (currentOptions, data) => currentOptions.cardCount > 3,
    })
    .addTextInput({
      path: 'column5ValueQueryName',
      name: 'Wert Spalte 5 (QueryName)',
      description: 'Name der Query',
      defaultValue: '',
      category: ['Kacheln'],
      showIf: (currentOptions, data) => currentOptions.cardCount > 4,
    })
    .addTextInput({
      path: 'column5ValueUnit',
      name: 'Wert Spalte 5 (Einheit)',
      description: 'Maßeinheit',
      defaultValue: '',
      category: ['Kacheln'],
      showIf: (currentOptions, data) => currentOptions.cardCount > 4,
    })
    .addNumberInput({
      path: 'statusDotCount',
      name: 'Anzahl Status-Punkte',
      defaultValue: 0,
      category: ['Punkte'],
      settings: {
        integer: true,
        min: 0,
        max: 5,
        step: 1,
      },
    })
    .addFieldNamePicker({
      path: 'dot1StatusQueryName',
      name: 'Wert Punkt 1 (QueryName)',
      description: 'Name der Query',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 0,
    })
    .addSliderInput({
      path: 'dot1XPosition',
      name: 'X-Position Punkt 1 (Prozent)',
      description: 'X-Position Punkt 1',
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 0,
    })
    .addSliderInput({
      path: 'dot1YPosition',
      name: 'Y-Position Punkt 1 (Prozent)',
      description: 'Y-Position Punkt 1',
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 0,
    })
    .addTextInput({
      path: 'dot1Title',
      name: 'Titel im Popover',
      description: 'Titel im Popover',
      defaultValue: '',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 0,
    })
    .addTextInput({
      path: 'dot1Description',
      name: 'Info-Text im Popover',
      description: 'Text im Popover',
      defaultValue: '',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 0,
    })
    .addFieldNamePicker({
      path: 'dot2StatusQueryName',
      name: 'Wert Punkt 2 (QueryName)',
      description: 'Name der Query',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 1,
    })
    .addSliderInput({
      path: 'dot2XPosition',
      name: 'X-Position Punkt 2 (Prozent)',
      description: 'X-Position Punkt 2',
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 1,
    })
    .addSliderInput({
      path: 'dot2YPosition',
      name: 'Y-Position Punkt 2 (Prozent)',
      description: 'Y-Position Punkt 2',
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 1,
    })
    .addTextInput({
      path: 'dot2Title',
      name: 'Titel im Popover',
      description: 'Titel im Popover',
      defaultValue: '',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 1,
    })
    .addTextInput({
      path: 'dot2Description',
      name: 'Info-Text im Popover',
      description: 'Text im Popover',
      defaultValue: '',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 1,
    })
    .addFieldNamePicker({
      path: 'dot3StatusQueryName',
      name: 'Wert Punkt 3 (QueryName)',
      description: 'Name der Query',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 2,
    })
    .addSliderInput({
      path: 'dot3XPosition',
      name: 'X-Position Punkt 3 (Prozent)',
      description: 'X-Position Punkt 3',
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 2,
      ...statusDotSettings,
    })
    .addSliderInput({
      path: 'dot3YPosition',
      name: 'Y-Position Punkt 3 (Prozent)',
      description: 'Y-Position Punkt 3',
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 2,
    })
    .addTextInput({
      path: 'dot3Title',
      name: 'Titel im Popover',
      description: 'Titel im Popover',
      defaultValue: '',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 2,
    })
    .addTextInput({
      path: 'dot3Description',
      name: 'Info-Text im Popover',
      description: 'Text im Popover',
      defaultValue: '',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 2,
    })
    .addFieldNamePicker({
      path: 'dot4StatusQueryName',
      name: 'Wert Punkt 4 (QueryName)',
      description: 'Name der Query',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 3,
    })
    .addSliderInput({
      path: 'dot4XPosition',
      name: 'X-Position Punkt 4 (Prozent)',
      description: 'X-Position Punkt 4',
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 3,
    })
    .addSliderInput({
      path: 'dot4YPosition',
      name: 'Y-Position Punkt 4 (Prozent)',
      description: 'Y-Position Punkt 4',
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 3,
    })
    .addTextInput({
      path: 'dot4Title',
      name: 'Titel im Popover',
      description: 'Titel im Popover',
      defaultValue: '',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 3,
    })
    .addTextInput({
      path: 'dot4Description',
      name: 'Info-Text im Popover',
      description: 'Text im Popover',
      defaultValue: '',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 3,
    })
    .addFieldNamePicker({
      path: 'dot5StatusQueryName',
      name: 'Wert Punkt 5 (QueryName)',
      description: 'Name der Query',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 4,
    })
    .addSliderInput({
      path: 'dot5XPosition',
      name: 'X-Position Punkt 5 (Prozent)',
      description: 'X-Position Punkt 5',
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 4,
    })
    .addSliderInput({
      path: 'dot5YPosition',
      name: 'Y-Position Punkt 5 (Prozent)',
      description: 'Y-Position Punkt 5',
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 4,
    })
    .addTextInput({
      path: 'dot5Title',
      name: 'Titel im Popover',
      description: 'Titel im Popover',
      defaultValue: '',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 4,
    })
    .addTextInput({
      path: 'dot5Description',
      name: 'Info-Text im Popover',
      description: 'Text im Popover',
      defaultValue: '',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 4,
    });
});
