export enum HeaderScope {
  Arburg = 'ARBURG',
  KochPac = 'KOCH Pac-Systeme',
  Homag = 'HOMAG Group',
  Schmalz = 'J.Schmalz',
  Buerkle = 'Robert Bürkle',
  UCSmartMaintenance = 'Smart Maintenance',
  UCPlantSimulation = 'Ultraefficient Plant Simulation',
}

export interface FabricHeaderOptions {
  text: string;
  headerScope: HeaderScope;
  showThreeDTour: boolean;
  threeDTourUrl: string;
  machineShortDescriptionLeft: string;
  machineShortDescriptionRight: string;
  cardCount: number;
  column1ValueQueryName: string;
  column2ValueQueryName: string;
  column3ValueQueryName: string;
  column4ValueQueryName: string;
  column5ValueQueryName: string;
  column1ValueUnit: string;
  column2ValueUnit: string;
  column3ValueUnit: string;
  column4ValueUnit: string;
  column5ValueUnit: string;
  statusDotCount: number;
  dot1StatusQueryName: string | undefined;
  dot1XPosition: number;
  dot1YPosition: number;
  dot1Title: string;
  dot1Description: string;
  dot2StatusQueryName: string | undefined;
  dot2XPosition: number;
  dot2YPosition: number;
  dot2Title: string;
  dot2Description: string;
  dot3StatusQueryName: string | undefined;
  dot3XPosition: number;
  dot3YPosition: number;
  dot3Title: string;
  dot3Description: string;
  dot4StatusQueryName: string | undefined;
  dot4XPosition: number;
  dot4YPosition: number;
  dot4Title: string;
  dot4Description: string;
  dot5StatusQueryName: string | undefined;
  dot5XPosition: number;
  dot5YPosition: number;
  dot5Title: string;
  dot5Description: string;
}

export class OptExtension {
  static isUseCase = (options: FabricHeaderOptions): boolean => {
    return options.headerScope === 'Smart Maintenance' || options.headerScope === 'Ultraefficient Plant Simulation';
  };

  static showKeyFactsBlock = (options: FabricHeaderOptions): boolean => {
    return !OptExtension.isUseCase(options) && !!options.machineShortDescriptionRight;
  };

  static machineHeader = (options: FabricHeaderOptions): string => {
    if (OptExtension.isUseCase(options) && options.headerScope === 'Ultraefficient Plant Simulation') {
      return 'fischer Lernfabrik';
    } else if (OptExtension.isUseCase(options)) {
      return '';
    } else {
      return options.headerScope;
    }
  };

  static showMachineDescriptionBlock = (options: FabricHeaderOptions): boolean => {
    return OptExtension.shortDescriptionHeader(options) !== null;
  };

  static shortDescriptionHeader = (options: FabricHeaderOptions): string | null => {
    if (OptExtension.isUseCase(options) && options.headerScope === 'Ultraefficient Plant Simulation') {
      return 'Kurzinfo zur Fabrik';
    } else if (OptExtension.isUseCase(options)) {
      return null;
    } else {
      return 'Kurzinfo zur Maschine';
    }
  };
}
