import React from 'react';
import { css, cx } from 'emotion';
import { KPIItem, KpiPanelOptions } from './KPI-Item';

interface KpiContainerProps {
  options: KpiPanelOptions[];
  width: number;
  height: number;
}

export const KPIContainer: React.FC<KpiContainerProps> = ({ options, width }: KpiContainerProps) => {
  return (
    <div
      className={cx(
        css`
          display: flex;
          column-gap: 20px;
          row-gap: 10px;
          flex-direction: ${width > 800 ? 'row' : 'column'};
        `
      )}
    >
      {options.map((option, index) => {
        return (
          <div
            key={index}
            className={cx(
              css`
                justify-self: center;
                flex: 1;
              `
            )}
          >
            <KPIItem
              header={option.header}
              state={option.state}
              value={option.value}
              unit={option.unit}
              deltaInPercent={option.deltaInPercent}
              width={width}
            ></KPIItem>
          </div>
        );
      })}
    </div>
  );
};
