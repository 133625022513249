import { PanelData } from '@grafana/data';

export const getValueFromQuery = (data: PanelData, refName: string | undefined): string => {
  if (!data || !data.request || !refName) {
    return '';
  }

  const item1 = data.request.targets.find((x) => x.refId === refName);
  if (item1) {
    const indexItem1 = data.request.targets.indexOf(item1);
    if (indexItem1 !== undefined) {
      const item1Values = data.series[indexItem1]?.fields.map((field) => field?.values.get(field.values.length - 1));

      if (item1Values?.length > 0) {
        return item1Values[item1Values.length - 1]?.toFixed(2);
      }
    }
  }

  return '';
};

export const getStringValueFromQuery = (
  data: PanelData,
  columnID: string | undefined,
  refName: string | undefined
): string => {
  if (!data || !data.request || !refName || !columnID) {
    return '';
  }

  const item1 = data.request.targets.find((x) => x.refId === refName);
  if (item1) {
    const indexItem1 = data.request.targets.indexOf(item1);
    if (indexItem1 !== undefined) {
      const item1Values = data.series[indexItem1]?.fields
        .map((field) => {
          if (field.name === columnID) {
            return field?.values.get(field.values.length - 1);
          }
        })
        .filter((element) => {
          return element !== null && element !== 'null' && element !== undefined;
        });
      if (item1Values?.length > 0) {
        return item1Values[item1Values.length - 1];
      }
    }
  }

  return '';
};

export function hackToString(input: unknown) {
  if ( typeof input === 'object' && input !== null && 'toString' in input) {
    return input.toString();
  } else {
    return ''
  }
}

export const getValuesFromQuery = <T extends unknown>(
  data: PanelData,
  refName: string | undefined,
  fieldName: string | undefined
): T[] => {
  if (!data || !data.request || !refName) {
    return [];
  }

  const item1 = data.request.targets.find((x) => x.refId === refName);
  if (item1) {
    const indexItem1 = data.request.targets.indexOf(item1);
    if (indexItem1 !== undefined) {
      const item1Values = data.series[indexItem1]?.fields.find((f) => f.name === fieldName!)?.values.toArray();
      if (item1Values && item1Values?.length > 0) {
        return item1Values;
      }
    }
  }

  return [];
};

export const getNumberFromQuery = (data: PanelData, refName: string | undefined): number => {
  const sStatusValue = getValueFromQuery(data, refName);
  return parseInt(sStatusValue, 10);
};

export const getSingleValueFromQuery = (
  data: PanelData,
  refName: string | undefined,
  fieldName: string | undefined
): string | undefined => {
  const values = getValuesFromQuery(data, refName, fieldName);
  if (values?.length > 0) {
    return values[0];
  }
  return undefined;
};

export const calcChangeInPercentage = (prevValue?: string, newValue?: string): number | undefined => {
  if (!prevValue || !newValue) {
    return undefined;
  }
  const prevVal = Number.parseFloat(prevValue);
  const newVal = Number.parseFloat(newValue);
  if (prevVal && newVal && prevVal !== 0) {
    return ((newVal - prevVal) / prevVal) * 100;
  }
  return undefined;
};

export enum DotState {
  green,
  red,
  white,
}

export const dotStateForNumber = (value: number) =>
  value === 1 ? DotState.green : value === 0 ? DotState.red : DotState.white;
