import React, { useEffect, useState } from 'react';
import { PanelData, PanelProps } from '@grafana/data';
import { OptExtension, FabricHeaderOptions } from '../types';
import { css, cx } from 'emotion';
import { stylesFactory } from '@grafana/ui';
import { getValueFromQuery } from 'uef-common';
import { KPIContainer } from './KPIContainer';
import { KpiPanelOptions } from './KPI-Item';
import { ImageWithDots } from './ImageWithDots';

interface Props extends PanelProps<FabricHeaderOptions> {}

const calcChangeInPercentage = (prevValue?: string, newValue?: string): number | undefined => {
  if (!prevValue || !newValue) {
    return undefined;
  }
  const prevVal = Number.parseFloat(prevValue);
  const newVal = Number.parseFloat(newValue);
  if (prevVal && newVal && prevVal !== 0) {
    return ((newVal - prevVal) / prevVal) * 100;
  }
  return undefined;
};

const widthBreakpoint = 800;

const getKpiPanelOptions = (
  data: PanelData,
  columnName: string | undefined,
  currentValue: KpiPanelOptions | undefined,
  width: number,
  unit: string
): KpiPanelOptions | undefined => {
  if (!columnName) {
    return undefined;
  }
  const value = getValueFromQuery(data, columnName);
  const deltaInPercent = calcChangeInPercentage(currentValue?.value, value);
  const kpiOptions: KpiPanelOptions = {
    header: columnName,
    value: value,
    deltaInPercent: deltaInPercent?.toFixed(1),
    state: deltaInPercent ? (deltaInPercent > 0 ? 'good' : 'bad') : 'none',
    width: width,
    unit: unit,
  };
  return kpiOptions;
};

export const HeaderComponent: React.FC<Props> = ({ options, data, width, height }) => {
  const styles = getStyles();
  const [currentValue1, setCurrentValue1] = useState<KpiPanelOptions | undefined>();
  const [currentValue2, setCurrentValue2] = useState<KpiPanelOptions | undefined>();
  const [currentValue3, setCurrentValue3] = useState<KpiPanelOptions | undefined>();
  const [currentValue4, setCurrentValue4] = useState<KpiPanelOptions | undefined>();
  const [currentValue5, setCurrentValue5] = useState<KpiPanelOptions | undefined>();
  const [kpiPanelOptions, setKpiPanelOptions] = useState<KpiPanelOptions[]>();

  useEffect(() => {
    setCurrentValue1((prev) =>
      getKpiPanelOptions(data, options.column1ValueQueryName, prev, width, options.column1ValueUnit)
    );
    setCurrentValue2((prev) =>
      getKpiPanelOptions(data, options.column2ValueQueryName, prev, width, options.column2ValueUnit)
    );
    setCurrentValue3((prev) =>
      getKpiPanelOptions(data, options.column3ValueQueryName, prev, width, options.column3ValueUnit)
    );
    setCurrentValue4((prev) =>
      getKpiPanelOptions(data, options.column4ValueQueryName, prev, width, options.column4ValueUnit)
    );
    setCurrentValue5((prev) =>
      getKpiPanelOptions(data, options.column5ValueQueryName, prev, width, options.column5ValueUnit)
    );
  }, [data, options, width]);

  useEffect(() => {
    const panelOptions: KpiPanelOptions[] = [];
    if (currentValue1 && options.cardCount > 0) {
      panelOptions.push(currentValue1!);
    }
    if (currentValue2 && options.cardCount > 1) {
      panelOptions.push(currentValue2!);
    }
    if (currentValue3 && options.cardCount > 2) {
      panelOptions.push(currentValue3!);
    }
    if (currentValue4 && options.cardCount > 3) {
      panelOptions.push(currentValue4!);
    }
    if (currentValue5 && options.cardCount > 4) {
      panelOptions.push(currentValue5!);
    }
    setKpiPanelOptions(panelOptions);
  }, [currentValue1, currentValue2, currentValue3, currentValue4, currentValue5, width, height, options.cardCount]);

  const isLarge = width > widthBreakpoint;

  const VerticalFiller = () => (
    <div
      className={cx(css`
        display: flex;
        flex: 1;
      `)}
    />
  );

  const UseCaseHeader = () => {
    if (OptExtension.isUseCase(options)) {
      return <h1>{options.headerScope}</h1>;
    }
    return null;
  };

  return (
    <div
      className={cx(
        css`
          width: ${width}px;
          height: ${height}px;
          padding: ${isLarge ? '3rem' : '1em'};
        `
      )}
    >
      {isLarge ? (
        // render large layout
        <div
          className={cx(
            css`
              height: 100%;
            `,
            styles.verticalContainer
          )}
        >
          <VerticalFiller />
          <UseCaseHeader />
          <div
            className={cx(
              css`
                flex: 4;
              `,
              styles.row
            )}
          >
            {OptExtension.showMachineDescriptionBlock(options) && (
              <div className={styles.textColumn}>
                <h1>{OptExtension.machineHeader(options)}</h1>
                <p className={styles.header}>{OptExtension.shortDescriptionHeader(options)}</p>
                <p className={styles.text}>{options.machineShortDescriptionLeft}</p>
              </div>
            )}
            <div
              className={cx(
                css`
                  flex: 3;
                  padding: 0 6rem;
                `
              )}
            >
              <ImageWithDots options={options} data={data} height={height} />
            </div>
            {OptExtension.showKeyFactsBlock(options) && (
              <div
                className={cx(
                  css`
                    margin-left: 5px;
                  `,
                  styles.textColumn
                )}
              >
                <p className={styles.header}>Keyfacts</p>
                <p className={styles.text}>{options.machineShortDescriptionRight}</p>
              </div>
            )}
          </div>
          <VerticalFiller />
          <KPIContainer options={kpiPanelOptions ?? []} width={width} height={height}></KPIContainer>
        </div>
      ) : (
        // render smartphone layout
        <div className={styles.verticalContainer}>
          <UseCaseHeader />
          <div
            className={cx(
              css`
                width: ${options.showThreeDTour ? '100%' : '80%'};
                height: ${options.showThreeDTour ? '310px' : 'initial'};
                max-height: ${options.showThreeDTour ? 'initial' : '284px'};
                margin-bottom: ${options.showThreeDTour ? '30px' : 'initial'};
                justify-content: center;
                display: flex;
              `
            )}
          >
            <ImageWithDots options={options} data={data} height={height} />
          </div>
          <h1>{OptExtension.machineHeader(options)}</h1>
          {OptExtension.showMachineDescriptionBlock(options) && (
            <div>
              <p className={styles.header}>{OptExtension.shortDescriptionHeader(options)}</p>
              <p>{options.machineShortDescriptionLeft}</p>
            </div>
          )}
          {OptExtension.showKeyFactsBlock(options) && (
            <div>
              <p className={styles.header}>Keyfacts</p>
              <p>{options.machineShortDescriptionRight}</p>
            </div>
          )}
          <div
            className={cx(css`
              margin-top: 40px;
            `)}
          >
            <KPIContainer options={kpiPanelOptions ?? []} width={width} height={height}></KPIContainer>
          </div>
        </div>
      )}
    </div>
  );
};

const getStyles = stylesFactory(() => {
  return {
    header: css`
      font-size: 1rem;
      font-weight: 600;
    `,
    row: css`
      display: flex;
    `,
    textColumn: css`
      flex: 1;
      align-self: center;
    `,
    verticalContainer: css`
      display: flex;
      flex-direction: column;
    `,
    text: css`
      word-break: break-word;
    `,
  };
});
